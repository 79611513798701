import React from 'react';

export type NutritionalInfoType = {
  calories: number;
  protein: number;
  carbs: number;
  fats: number;
};

export type MacrosContainerType = {
  nutritionalInfo: NutritionalInfoType;
  size?: string;
  rounded?: boolean;
  fullText?: boolean;
  textClass?: string;
  upsell?: boolean;
};

export const MacrosContainer: React.FC<MacrosContainerType> = ({
  nutritionalInfo,
  size,
  rounded = false,
  fullText = false,
  textClass = '',
  upsell = false,
}) => {
  const containerStyles = size === 'small' ? '' : '';
  const styles = size === 'small' ? 'px-1 ' : 'md:px-2 ';
  const pl = size === 'small' ? 'pl-[3px]' : 'pl-1';
  const mb = size === 'nomargin' ? '' : '';
  const roundedStyle = rounded ? 'rounded-2xl ' : '';
  const textSize = `${upsell ? 'text-xxs' : 'text-xxs md:text-xs'} ${textClass}`;

  return (
    <div
      className={`flex h-6 w-full items-center justify-evenly bg-black font-interSemibold text-white ${roundedStyle} ${containerStyles} ${mb} p-1.5`}
    >
      <span className={`px-1 md:px-0.5  lg:px-1 ${styles} ${textSize}`}>
        {nutritionalInfo?.calories?.toFixed(0)}
        <span className={`${pl} font-inter`}>CAL</span>
      </span>
      <span className={`px-1  md:px-0.5 lg:px-1 ${styles} ${textSize}`}>
        {nutritionalInfo?.protein?.toFixed(0)}
        <span className={`${pl} font-inter`}>{fullText ? 'Protein' : 'P'}</span>
      </span>
      <span className={`px-1  md:px-0.5 lg:px-1 ${styles} ${textSize}`}>
        {nutritionalInfo?.carbs?.toFixed(0)}
        <span className={`${pl} font-inter`}>{fullText ? 'Carbs' : 'C'}</span>
      </span>
      <span className={`px-1 md:px-0.5 lg:px-1 ${styles} ${textSize}`}>
        {nutritionalInfo?.fats?.toFixed(0)}
        <span className={`${pl} font-inter`}>{fullText ? 'Fats' : 'F'}</span>
      </span>
    </div>
  );
};
