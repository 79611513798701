import React from 'react';

import SwapHoriz from '@/components/icons/SwapHoriz';
import { SwapStates } from '@/utils/constants';

type SwapProps = {
  onClick?: () => void;
  text?: string;
  state?: string;
};

export const Swap: React.FC<SwapProps> = ({
  onClick,
  text = 'Swap',
  state,
}) => {
  const colour =
    state === SwapStates.SWAP_PENDING ? 'fill-black' : 'fill-attention';
  const textColour =
    state === SwapStates.SWAP_PENDING ? 'text-black' : 'text-attention';
  return (
    <div
      onClick={onClick}
      className="flex h-full cursor-pointer items-center justify-center gap-1"
    >
      <SwapHoriz width={16} height={16} color={colour} />
      <span
        className={`font-interSemibold text-sm tracking-[-0.2px] 2xl:text-base ${textColour}`}
      >
        {text}
      </span>
    </div>
  );
};
