import Image from 'next/image';
import React from 'react';

type QuantityButtonProps = {
  qty: number;
  handleAddProduct: () => void;
  handleDeleteProduct: () => void;
};

// Name this Quantity due to conflict with QuantityButtonV2 in MenuBuilderCard
const QuantityButton: React.FC<QuantityButtonProps> = ({
  qty,
  handleAddProduct,
  handleDeleteProduct,
}) => {
  const pillClass = 'py-1 px-2 bg-grey-liter rounded-full';
  const textClass = 'text-xs font-interMedium';

  const imageSrc =
    // placeholder for appropriate logic
    qty === 1 || qty === undefined
      ? '/assets/icons/delete.svg'
      : '/assets/icons/black-remove.svg';

  return (
    <div className={`flex items-center ${pillClass} cursor-pointer gap-3`}>
      <button
        className="flex size-5 items-center justify-center"
        onClick={handleDeleteProduct}
      >
        <Image src={imageSrc} alt="remove" width={16} height={16} priority />
      </button>

      <span className={`${textClass} text-black`}>{qty || 1}</span>

      <button
        className="flex size-5 items-center justify-center"
        onClick={handleAddProduct}
      >
        <Image
          className="size-full "
          src="/assets/icons/black-add.svg"
          alt="add"
          width={16}
          height={16}
          priority
        />
      </button>
    </div>
  );
};

export default QuantityButton;
