import React from 'react';

interface ButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  size?: string;
  text?: string;
}

const baseStyle =
  'text-white cursor-pointer text-base font-inter flex items-center justify-center';

const SwapButton: React.FC<ButtonProps> = ({
  disabled,
  onClick,
  size = '',
  text = 'Swap',
  ...rest
}: ButtonProps) => {
  let width = 'w-full h-9 md:h-[38px] rounded-b-xl';
  if (size === 'small') {
    width = 'h-9 rounded-full px-2 py-3';
  }
  const buttonClass = `${baseStyle} ${width} ${disabled ? ' bg-grey-charcoal' : 'bg-primary'}`;
  return (
    <button
      type="button"
      className={buttonClass}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      <span className="font-interSemibold text-sm md:text-base">{text}</span>
    </button>
  );
};

export default SwapButton;
