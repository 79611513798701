import React from 'react';

const SwapHoriz = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.color}
      {...props}
    >
      <path d="M3.87062 10.2982L0.934082 7.36163L3.87062 4.42509L4.48315 5.03119L2.58112 6.93306H7.49699V7.7902H2.58112L4.48315 9.69223L3.87062 10.2982ZM9.99754 6.55146L9.38502 5.94537L11.2871 4.04333H6.37117V3.18635H11.2871L9.38502 1.28432L9.99754 0.678223L12.9341 3.61476L9.99754 6.55146Z" />
    </svg>
  );
};

export default SwapHoriz;
